import type { Database } from '@openesg/supabase'
import { PRODUCT_TABLE_NAME } from '@openesg/supabase/constants/tableNames'
import { type ProductTypeRow, type ProductType } from '@openesg/supabase/models'
import { CompanyTypeRow } from '~~/server/types'

export const fetchOwnedProduct = () => {
  return useSupabaseClient<Database>()
    .from(PRODUCT_TABLE_NAME)
    .select(`*`)
    .limit(1)
    .maybeSingle<ProductTypeRow>()
}
export const updateOwnedProduct = (value: ProductType['Insert']) => {
  return useSupabaseClient<Database>()
    .from(PRODUCT_TABLE_NAME)
    .upsert({ ...value })
    .select()
    .maybeSingle()
}

export const checkIsSlugValid = async (
  id: ProductTypeRow['id'],
  companyId: CompanyTypeRow['id'],
  slug: ProductTypeRow['slug']
) => {
  // Allow duplicate product slugs across different company
  const { data, error } = await useSupabaseClient<Database>()
    .from(PRODUCT_TABLE_NAME)
    .select()
    .eq('slug', slug)
    .eq('company_id', companyId)
    .neq('id', id)
    .maybeSingle()
  if (error) throw error
  return !data
}

export const fetchPublicData = (
  companySlug: CompanyTypeRow['slug'],
  productSlug: ProductTypeRow['slug']
) =>
  $fetch(`/api/public/results/${companySlug}/${productSlug}`, {
    method: 'GET',
  })

export const uploadProductImage = (
  companyId: CompanyTypeRow['id'],
  productId: ProductTypeRow['id'] | undefined,
  currentFile: File
) => {
  return useSupabaseClient<Database>()
    .storage.from('publicUploads')
    .upload(
      `${companyId}/product/${productId || 'temp'}/image/${currentFile.name}`,
      currentFile
    )
}

export const fetchProductImage = (url: string) => {
  return useSupabaseClient<Database>()
    .storage.from('publicUploads')
    .getPublicUrl(url)
}

export const removeProductImage = (url: string) => {
  return useSupabaseClient<Database>()
    .storage.from('publicUploads')
    .remove([url])
}

export const moveImageOnCreate = async (
  companyId: CompanyTypeRow['id'],
  productId: ProductTypeRow['id'],
  fromPath: string
) => {
  const fileName = fromPath.split('/').pop()
  const toPath = `${companyId}/product/${productId}/image/${fileName}`
  const response = await useSupabaseClient<Database>()
    .storage.from('publicUploads')
    .move(fromPath, toPath)
  return response.data ? toPath : null
}
